
import {
  convertDecimalWithComma,
  formatDate
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { STIController } from "@/app/ui/controllers/STIController";
import {
  STIReverseScanDetail,
  STIReverseScanGenerateData
} from "@/domain/entities/STI";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
}
)
export default class STIDetailReverseScan extends Vue {
  controller = STIController;

  refs: any = "";
  mounted() {
    this.refs = this.$refs;
    this.fetchDetail();
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get detailData(): STIReverseScanDetail {
    return this.controller.STIReverseScanDetailData;
  }

  get isError() {
    return STIController.isErrorPrint;
  }
  get errorCause() {
    return STIController.errorPrintCause;
  }

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "text-left w-12",
        render: (_item: STIReverseScanGenerateData, index: number) =>
          `<div class="text-sm text-black-lp-300">${index + 1}</div>`
      },
      {
        name: "No. Bag",
        styleHead: "text-left w-40",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300">${item.bagNo || "-"}</div>`
      },
      {
        name: "No. STT",
        styleHead: "text-left w-40",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300">${item.sttNo || "-"}</div>`
      },
      {
        name: "No. Referensi",
        styleHead: "text-left w-40",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300 break-all">${item.refNo || "-"}</div>`
      },
      {
        name: "Jenis Pengiriman",
        styleHead: "text-left w-36",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300 px-1 bg-gray-lp-200 rounded">${item.sttProductType ||
            "-"}</div>`
      },
      {
        name: "Komoditas",
        styleHead: "text-left w-50",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300">${item.sttCommodityName ||
            "-"}</div>`
      },
      {
        name: "Total koli",
        styleHead: "text-left w-36",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300">${item.piece.length}</div>`
      },
      {
        name: "Gross Weight",
        styleHead: "text-left w-36",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300">${this.convertDecimalWithComma(
            item.sttGrossWeight
          )} kg</div>`
      },
      {
        name: "Volume Weight",
        styleHead: "text-left w-36",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300">${this.convertDecimalWithComma(
            item.sttVolumeWeight
          )} kg</div>`
      },
      {
        name: "Kota asal",
        styleHead: "text-left w-50",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300">${item.sttOriginCityId} - ${item.sttOriginCityName}</div>`
      },
      {
        name: "Kota tujuan",
        styleHead: "text-left w-50",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300">${item.sttDestinationCityId} - ${item.sttDestinationCityName}</div>`
      },
      {
        name: "STI - DEST",
        styleHead: "text-left w-36",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300 text-left">
            ${`<img src=${require(`@/app/ui/assets/svg/${
              item.isStiDest === 1 ? "check-circle.svg" : "close-circle.svg"
            }`)}`} width="24" height="24" />
          </div>`
      },
      {
        name: "Penanda Paket",
        styleHead: "text-left w-36",
        render: (item: STIReverseScanGenerateData) =>
          `<div class="text-sm text-black-lp-300 text-left">
            ${item.statusReturn || "-"}
          </div>`
      }
    ];
  }

  fetchDetail(): void {
    this.controller.getDetailSTIReverseScan(this.id);
  }

  formatDate(date: string): string {
    if (!date) return "-";
    return formatDate(date);
  }

  convertDecimalWithComma(decimal: number): number {
    return convertDecimalWithComma(decimal, 2);
  }

  
  async printSummary() {
    const print: any = await import(
      "@/app/ui/views/out-going-shipment/sti/component/modules/generate-reverse-scan.vue"
    );
    const logo = await import(
      `@/app/ui/assets/images/logo-${this.roleAccount}.png`
    );
    print.default.methods.printSummary(this.id, logo.default);
  }

  async printManifest() {
    const print: any = await import(
      "@/app/ui/views/out-going-shipment/sti/component/modules/generate-reverse-scan.vue"
    );
    const logo = await import(
      `@/app/ui/assets/images/logo-${this.roleAccount}.png`
    );
    print.default.methods.print(this.id, logo.default);
  }

    // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  
  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
