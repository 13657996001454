
import { FlagsPermissionOutgoingShipmentSTI } from '@/feature-flags/flags-out-going-shipment-sti';
import { Options, Vue } from 'vue-class-component';
import STIDetailV1 from './index.vue';
import STIDetailReverseScan from '../../reverse-scan/sti-detail-reverse-scan.vue';

@Options({
  components: {
    STIDetailV1,
    STIDetailReverseScan
  }
})
export default class STIDetail extends Vue {
  get isReverseScanSTI(): boolean {
    return FlagsPermissionOutgoingShipmentSTI.flag_reverse_scan_sti_enable.isEnabled();
  }

  get renderedPage(): string {
    return this.isReverseScanSTI ? 'STIDetailReverseScan' : 'STIDetailV1'
  }
}
