
import { FlagsPermissionOutgoingShipmentSTI } from '@/feature-flags/flags-out-going-shipment-sti';
import { Options, Vue } from 'vue-class-component';
import STIListV1 from './index.vue'
import STIListReverseScan from './reverse-scan/sti-list-reverse-scan.vue';

@Options({
  components: {
    STIListV1,
    STIListReverseScan
  }
})
export default class STIList extends Vue {
  get isReverseScanSTI(): boolean {
    return FlagsPermissionOutgoingShipmentSTI.flag_reverse_scan_sti_enable.isEnabled();
  }

  get renderedPage(): string {
    return this.isReverseScanSTI ? 'STIListReverseScan' : 'STIListV1';
  }
}
