
import { prop, Vue } from "vue-class-component";

class Props {
  isStiDest = prop<boolean>({
    default: false,
    required: false,
    type: Boolean
  });
  regionName = prop<string>({
    default: "",
    required: false,
    type: String
  });
}

export default class DangerousGoodsIcon extends Vue.with(Props) {}
