/* eslint-disable @typescript-eslint/no-empty-function */
import {
  convertDecimalWithComma,
  dateToDateString,
  ellipsisString,
  isMigrateFromElexys
} from "@/app/infrastructures/misc/Utils";
import { StiManifestData } from "@/domain/entities/STI";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { createI18n } from "vue-i18n";
import { AccountController } from "@/app/ui/controllers/AccountController";

let doc: any;
let translate: any = () => {};

const columns = () => [
  { header: "No.", dataKey: "no" },
  {
    header: `No. STT`,
    dataKey: "sttNumber"
  },
  { header: translate("Produk"), dataKey: "productType" },
  { header: translate("Total\nKoli"), dataKey: "piecesNumber" },
  { header: translate("Berat\nKotor"), dataKey: "grossWeight" },
  { header: translate("Berat\nDimensi"), dataKey: "volumeWeight" },
  { header: "Origin", dataKey: "originCity" },
  { header: translate("Dest."), dataKey: "destCity" },
  { header: translate("Wilayah"), dataKey: "region" },
  { header: translate("Catatan"), dataKey: "catatan" }
];
!isMigrateFromElexys() && columns().splice(1, 1);

const columnStyles: any = {
  0: { cellWidth: 10 },
  1: { cellWidth: 30 },
  2: { cellWidth: 20 },
  3: { cellWidth: 13 },
  4: { cellWidth: 22 },
  5: { cellWidth: 22 },
  6: { cellWidth: 20 },
  7: { cellWidth: 20 }
};
isMigrateFromElexys() && delete columnStyles["3"];

const productIdx = 2;
const drawCellConditions = (cell: any) => [
  cell.column.dataKey === "grossWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "volumeWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "piecesNumber" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "productType" && cell.row.section === "head",
  cell.row.cells[productIdx].text[0] === "",
  cell.column.dataKey === "productType" && cell.row.section === "body",
  cell.column.dataKey === "originCity" && cell.row.section === "body",
  cell.column.dataKey === "destCity" && cell.row.section === "body",
  cell.column.dataKey === "region" && cell.row.section === "body"
];

const drawCellData = (cell: any) => {
  const grossWeightIdx = 4;
  const volumeWeightIdx = 5;
  const totalPiecesIdx = 3;
  const originCityIdx = 6;
  const destCityIdx = 7;
  const regionIdx = 8;

  if (drawCellConditions(cell)[0]) {
    cell.row.cells[grossWeightIdx].styles = {
      ...cell.row.cells[grossWeightIdx].styles,
      halign: "center"
    };
  }
  if (drawCellConditions(cell)[1]) {
    cell.row.cells[volumeWeightIdx].styles = {
      ...cell.row.cells[volumeWeightIdx].styles,
      halign: "center"
    };
  }
  if (drawCellConditions(cell)[2]) {
    cell.row.cells[totalPiecesIdx].styles = {
      ...cell.row.cells[totalPiecesIdx].styles,
      halign: "center"
    };
  }
  if (drawCellConditions(cell)[3]) {
    cell.row.cells[productIdx].styles = {
      ...cell.row.cells[productIdx].styles,
      cellPadding: {
        ...cell.row.cells[productIdx].styles.cellPadding,
        left: 0.7
      }
    };
  }
  if (drawCellConditions(cell)[4]) {
    Object.keys(cell.row.cells).forEach(key => {
      Object.keys(cell.row.cells[key].text).forEach(text => {
        cell.row.cells[key].text[text] =
          cell.row.cells[key].text[parseInt(text) + 1] || "";
      });
    });
  }
  if (drawCellConditions(cell)[5]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[productIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[productIdx].x + 0.7,
          cell.row.cells[productIdx].y +
            cell.row.cells[productIdx].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[productIdx].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[productIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellConditions(cell)[6]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[originCityIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[originCityIdx].x + 0.7,
          cell.row.cells[originCityIdx].y +
            cell.row.cells[originCityIdx].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[originCityIdx].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[originCityIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellConditions(cell)[7]) {
    doc.setFillColor("#ebecf0");
    cell.row.cells[destCityIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[destCityIdx].x + 0.7,
          cell.row.cells[destCityIdx].y +
            cell.row.cells[destCityIdx].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[destCityIdx].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[destCityIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellConditions(cell)[8]) {
    cell.row.cells[regionIdx].styles = {
      ...cell.row.cells[regionIdx].styles,
      halign: "left"
    };
  }
};

const manifestPdfV2 = (
  dataHead: StiManifestData,
  data: Array<any>,
  logo: string
) => {
  doc = new jsPDF("p", "mm", "a4", true);

  const i18nInstance = createI18n({
    legacy: false,
    locale: AccountController.accountData.account_type_detail.countryCode.toLowerCase(),
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  translate = i18nInstance.global.t;

  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("STI Manifest"), 10, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(10, 25, 190, 0.2, "F");
  doc.setFontSize(7);
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");

  // left column
  doc.setTextColor("#4d4d4d");
  doc.setFillColor("#ebecf0");
  doc.text(translate("Nama Consolidator"), 10, 32);
  doc.text(translate(":"), 36, 32);
  doc.text(ellipsisString(dataHead.consolidatorName, 70), 38, 32);

  doc.setTextColor("#4d4d4d");
  doc.text(translate("Kota Asal"), 10, 38);
  doc.text(translate(":"), 36, 38);
  doc.text(dataHead.originConsolidator, 38, 38);

  doc.setTextColor("#4d4d4d");
  doc.text(translate("Tanggal STI"), 10, 44);
  doc.text(translate(":"), 36, 44);
  doc.text(dateToDateString(new Date(dataHead.stiDate)), 38, 44);

  // right column
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total STT"), 120, 32);
  doc.text(":", 150, 32);
  doc.text(`${dataHead.totalStt}`, 152, 32);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total Koli"), 120, 38);
  doc.text(":", 150, 38);
  doc.text(`${dataHead.totalPiece}`, 152, 38);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total Berat Kotor"), 120, 44);
  doc.text(":", 150, 44);
  doc.text(
    `${convertDecimalWithComma(dataHead.totalGrossWeight, 2)} Kg`,
    152,
    44
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text(translate("Total Berat Dimensi"), 120, 50);
  doc.text(":", 150, 50);
  doc.text(
    `${convertDecimalWithComma(dataHead.totalVolumeWeight, 2)} Kg`,
    152,
    50
  );

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  doc.autoTable({
    body: data,
    columns: columns(),
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 55 : 0,
    margin: { top: 7, left: 7, right: 7, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "middle",
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: columnStyles,
    didDrawCell: (cell: any) => {
      // top-border
      if (cell.section === "body") {
        doc.setFillColor("#e0e0e0");
        doc.rect(
          cell.row.cells[0].x + 0.05,
          cell.row.cells[0].y + cell.row.height,
          277,
          0.2,
          "F"
        );
        doc.setFillColor("#fff");
      }
    },
    willDrawCell: (cell: any) => {
      drawCellData(cell);
      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });
  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text(translate("Staff Admin"), 19 + 90, lastY);
  doc.text("(.................................)", 14 + 90, lastY + 20);
  doc.text(translate("Kurir"), 60 + 90, lastY);
  doc.text("(.................................)", 50 + 90, lastY + 20);
  doc.text(translate("Operations Supervisor"), 84 + 90, lastY);
  doc.text("(.................................)", 84 + 90, lastY + 20);

  doc.setProperties({
    title: "STI Manifest"
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

export default manifestPdfV2;
