import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/app/ui/assets/svg/sti-reverse-scan/icon-sti-dest.svg'
import _imports_1 from '@/app/ui/assets/svg/sti-reverse-scan/icon-sti.svg'


const _hoisted_1 = {
  class: "flex flex-col gap-4 justify-center items-center",
  style: {"width":"fit-content"}
}
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "icon-sti-sti-dest",
  width: "264",
  height: "110"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "icon-sti",
  width: "150",
  height: "110"
}
const _hoisted_4 = {
  key: 2,
  class: "px-4 py-2 rounded-full bg-green-lp-100 text-white text-2xl font-bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.isStiDest)
      ? (_openBlock(), _createBlock("img", _hoisted_2))
      : (_openBlock(), _createBlock("img", _hoisted_3)),
    (_ctx.regionName)
      ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.regionName), 1))
      : _createCommentVNode("", true)
  ]))
}