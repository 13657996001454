import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "p-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BAGIcon = _resolveComponent("BAGIcon")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    (_ctx.isSTT)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.decideSTTIconComponent), {
          key: 0,
          flag: _ctx.flag,
          "region-name": _ctx.regionName,
          "is-sti-dest": _ctx.isStiDest,
          "is-underpaid": _ctx.isUnderpaid
        }, null, 8, ["flag", "region-name", "is-sti-dest", "is-underpaid"]))
      : (_openBlock(), _createBlock(_component_BAGIcon, {
          key: 1,
          "region-name": _ctx.regionName,
          "is-sti-dest": _ctx.isStiDest
        }, null, 8, ["region-name", "is-sti-dest"]))
  ]))
}