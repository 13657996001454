
import { prop, Vue } from "vue-class-component";

class Props {
  total = prop<number>({
    default: 0,
    required: true,
    type: Number
  });
  current = prop<number>({
    default: 0,
    required: true,
    type: Number
  });
  posName = prop<string>({
    default: "",
    required: true,
    type: String
  });
}

export default class SummaryCard extends Vue.with(Props) {
  get decideBgColor() {
    if (this.current >= this.total) return "green";
    return "red";
  }
}
