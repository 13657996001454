
import { Options, Vue } from "vue-class-component";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import { STIController } from "@/app/ui/controllers/STIController";
import { STIData } from "@/domain/entities/STI";
import { FlagsPermissionOutgoingShipmentSTI } from "@/feature-flags/flags-out-going-shipment-sti";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { STISTTDueSummaryListRequest } from "@/data/payload/api/StiApiRequest";
import debounce from "lodash/debounce";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import router from "@/app/ui/router";

@Options({
  components: {
    Skeleton
  }
})
export default class STIListReverseScan extends Vue {
  print: any = "";
  intervalId: any = "";

  beforeMount() {
    STIController.setFilter({
      startDate: "",
      endDate: "",
      search: ""
    });
    this.fetchNeedStiSummary();
  }

  mounted() {
    this.print = this.$refs.print;
    STIController.initPagination();
    this.intervalId = setInterval(() => {
      this.fetchNeedStiSummary();
    }, 600000); // 600000 millisecond = 10 minute
  }

  unmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  gotoNeedToStiPage() {
    router.push({
      name: "need-to-sti-booking",
      query: { isSti: String(true) }
    });
  }

  get getTitle() {
    return this.$route.meta.name;
  }
  get ableToCreate() {
    return FlagsPermissionOutgoingShipmentSTI.permission_sti_create.isEnabled();
  }
  get ableToDetail() {
    return FlagsPermissionOutgoingShipmentSTI.permission_sti_detail_view.isEnabled();
  }

  // filter search
  get searchValue() {
    return STIController.search;
  }

  onSearch(value: string) {
    STIController.setSearch(value);
    STIController.setFirstPage();
    this.fetchSTIList();
  }

  clearSearch() {
    STIController.setSearch("");
    this.fetchSTIList();
  }

  // filter date
  get periodeStart() {
    return STIController.periodeStart;
  }

  get periodeEnd() {
    return STIController.periodeEnd;
  }

  setPeriode(value: Array<any>) {
    STIController.setPeriodeStart(value[0]);
    STIController.setPeriodeEnd(value[1]);
    value[0] && value[1] && this.fetchSTIList();
  }

  fetchSTIList() {
    STIController.fetchSTIList("v2");
  }

  // goToUpdate
  goToUpdate() {
    this.$router.push("/station-transit-in/update");
  }

  get isLoading() {
    return STIController.isLoading;
  }

  get isLoadingSTTSummary() {
    return STIController.isLoadingSTTSummary;
  }

  get errorCause() {
    return STIController.errorCause;
  }

  fetchNeedStiSummary() {
    const payload = new STISTTDueSummaryListRequest({
      bookedId: 0,
    });

    STIController.getSTISTTDueSummary(payload);
  }

  onRefreshSTISummary = debounce(() => {
    this.fetchNeedStiSummary();
  }, 250);

  get STISTTDueSummaryData() {
    return STIController.STISTTDueSummaryData;
  }

  formatSTTNumber(value: string) {
    return formatInputMoney(value.replace(/\D+/g, ""));
  }

  // Table

  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-12 text-left",
        render: (_item: STIData, index: any) => {
          return `<div class="overflow-ellipsis text-left">${index +
            1 +
            this.pagination.limit * (this.pagination.page - 1)}</div>`;
        }
      },
      {
        name: this.$t("ID Manifest"),
        styleHead: "w-32 whitespace-nowrap text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiId}</div>`;
        }
      },
      {
        name: this.$t("Total STT"),
        styleHead: "w-24 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiTotalStt}</div>`;
        }
      },
      {
        name: this.$t("Total Koli"),
        styleHead: "w-24 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiTotalPieces}</div>`;
        }
      },
      {
        name: this.$t("Tanggal Manifest"),
        styleHead: "w-44 text-left",
        render: (item: STIData) => {
          return `<div class="flex flex-col"><div class="overflow-ellipsis text-left">${formatDateWithoutTime(
            item.stiCreatedAt
          )}<div class="overflow-ellipsis text-gray-lp-600 break-all">${
            item.stiCreatedName
          }</div></div>`;
        }
      },
      {
        name: this.$t("STI - DEST"),
        styleHead: "w-36 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiTotalSTIDest}</div>`;
        }
      },
      {
        name: this.$t("Segera POD"),
        styleHead: "w-36 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiTotalStatusReturnPOD}</div>`;
        }
      },
      {
        name: this.$t("Segera HAL"),
        styleHead: "w-36 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiTotalStatusReturnHAL}</div>`;
        }
      },
      {
        name: this.$t("Segera RTS"),
        styleHead: "w-36 text-left",
        render: (item: STIData) => {
          return `<div class="overflow-ellipsis text-left">${item.stiTotalStatusReturnRTS}</div>`;
        }
      },
      {
        name: "Atur",
        key: "button_column",
        styleHead: "w-24 text-left",
        styleCustom: "padding-action-sti",
        styleButton: (item: STIData) => {
          let groupOptions = [] as any[];
          const printSummary = {
            icon: "print-summary",
            label: this.$t("Print ringkasan"),
            clickFunction: () => this.printSummary(item.stiId)
          };

          const printPdf = {
            icon: "printer",
            label: this.$t("Print PDF"),
            clickFunction: () => this.printManifest(item.stiId)
          };

          const downloadExcel = {
            icon: "document-download-outline-grey",
            label: this.$t("Download excel"),
            clickFunction: () =>
              STIController.downloadSti({ id: item.stiId, version: "v2" })
          };

          const printSttNeedSti = {
            icon: "printer",
            label: "Print STT belum STI",
            clickFunction: () => this.printManifest(item.stiId, true)
          };

          const downloadExcelSttNeedSti = {
            icon: "document-download-outline-grey",
            label: "Download STT belum STI",
            clickFunction: () =>
              STIController.downloadSti({
                id: item.stiId,
                version: "v2",
                noSti: true
              })
          };

          if (item.hasSttNeedSti) {
            groupOptions = [
              printSummary,
              printPdf,
              printSttNeedSti,
              downloadExcel,
              downloadExcelSttNeedSti
            ]
          } else {
            groupOptions = [printSummary, printPdf, downloadExcel];
          }

          return {
            outline: true,
            icon: "option-gray",
            title: "",
            textColor: "black",
            color: "gray-lp-400",
            clickFunction: () => null,
            groupOptions: groupOptions
          };
        }
      }
    ];
  }

  get pagination() {
    return STIController.STIList.pagination;
  }

  async printSummary(id: any) {
    const print: any = await import(
      "@/app/ui/views/out-going-shipment/sti/component/modules/generate-reverse-scan.vue"
    );
    const logo = await import(
      `@/app/ui/assets/images/logo-${this.roleAccount}.png`
    );
    print.default.methods.printSummary(id, logo.default);
  }

  async printManifest(id: any, isNeedToSti = false) {
    const print: any = await import(
      "@/app/ui/views/out-going-shipment/sti/component/modules/generate-reverse-scan.vue"
    );
    const logo = await import(
      `@/app/ui/assets/images/logo-${this.roleAccount}.png`
    );

    if (isNeedToSti) {
      print.default.methods.printSttNeedSti(id, logo.default);
    } else {
      print.default.methods.print(id, logo.default);
    }
  }

  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }

  get STIList() {
    return STIController.STIList.data;
  }

  goToDetail(item: STIData) {
    this.$router.push(
      `/station-transit-in/detail/${item.stiId}?params=STI Manifest - ${item.stiId}`
    );
  }
}
