
import { Options, prop, Vue } from "vue-class-component";

class Props {
  isUnderpaid = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({})
export default class InvalidIcon extends Vue.with(Props) {}
