
import { Options, Vue, prop } from "vue-class-component";

class Props {
  flag = prop<string>({
    type: String,
    default: "",
  });
  regionName = prop<string>({
    type: String,
    default: "",
  });
  isStiDest = prop<boolean>({
    type: Boolean,
    default: false
  })
}

@Options({})
export default class RTSIcon extends Vue.with(Props) {}
