import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/app/ui/assets/svg/sti-reverse-scan/icon-scan-failed-underpaid.svg'
import _imports_1 from '@/app/ui/assets/svg/sti-reverse-scan/icon-scan-failed.svg'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: "icon-scan-failed",
  width: "388",
  height: "140"
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1,
  alt: "icon-scan-failed",
  width: "388",
  height: "140"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isUnderpaid)
    ? (_openBlock(), _createBlock("img", _hoisted_1))
    : (_openBlock(), _createBlock("img", _hoisted_2))
}