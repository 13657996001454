
import { AccountController } from "@/app/ui/controllers/AccountController";
import { STIController } from "@/app/ui/controllers/STIController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { Vue } from "vue-class-component";
import generateManifest from "./manifest-pdf-v2";
import generateSummaryManifest from "@/app/ui/modules/summary";
import {
  convertDecimalWithComma,
  dateToDateString,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";

export default class Generate extends Vue {
  // get profile account
  get dataProfile() {
    return AccountController.accountData;
  }
  async print(id: number, isDetail = false) {
    // logo
    MainAppController.showLoading();

    const printManifest =
      isDetail || (await STIController.getStiManifestDetail({ id, version: "v1" }));
    if (printManifest) {
      this.onAssignValuePrintData();
    }
    MainAppController.closeLoading();
  }

  onAssignValuePrintData() {
    const element: any = document;
    const logo = element.getElementById("logo").src;
    const dataHead = STIController.stiManifestData;
    const data: Array<any> = [];
    dataHead.stt.forEach((item, index) => {
      data.push({
        no: index + 1,
        sttNumber: "",
        productType: "",
        piecesNumber: "",
        grossWeight: "",
        volumeWeight: "",
        originCity: "",
        destCity: "",
        region: "",
        catatan: ""
      });

      const sttNumber = data[index].sttNumber ? "\n\n" : "";
      const productType = data[index].productType ? "\n\n" : "";
      const piecesNumber = data[index].piecesNumber ? "\n\n" : "";
      const grossWeight = data[index].grossWeight ? "\n\n" : "";
      const volumeWeight = data[index].volumeWeight ? "\n\n" : "";
      const originCity = data[index].originCity ? "\n\n" : "";
      const destCity = data[index].destCity ? "\n\n" : "";
      const region = data[index].region ? "\n\n" : "";

      data[index] = {
        ...data[index],
        sttNumber: `${data[index].sttNumber}${sttNumber}${item.sttNo}`,
        productType: `${data[index].productType}${productType}${item.sttProductType}`,
        piecesNumber: `${data[index].piecesNumber}${piecesNumber}${item.sttTotalPiece}`,
        grossWeight: `${data[index].grossWeight}${grossWeight}${item.stiGrossWeight} Kg`,
        volumeWeight: `${
          data[index].volumeWeight
        }${volumeWeight}${convertDecimalWithComma(item.stiVolumeWeight, 2)} Kg`,
        originCity: `${data[index].originCity}${originCity}${item.stiOriginCity}`,
        destCity: `${data[index].destCity}${destCity}${item.stiDestCity}`,
        region: `${data[index].region}${region}${item.stiDestRegion}`,
        catatan: ""
      };
    });
    generateManifest(dataHead, data, logo);
  }

  async printSummary(id: number, isDetail = false) {
    // logo
    MainAppController.showLoading();
    const element: any = document;
    const logo = element.getElementById("logo").src;

    const printManifest =
      isDetail || (await STIController.getStiManifestDetail({ id, version: "v1" }));
    if (printManifest) {
      const dataHead = STIController.stiManifestData;

      const fields = [
        {
          name: "Kota Asal",
          value: dataHead.originConsolidator
        },
        {
          name: "Nama Consolidator",
          value: ellipsisString(dataHead.consolidatorName, 21)
        },
        {
          name: "Tanggal STI",
          value: dateToDateString(dataHead.stiDate)
        },
        {
          name: "Total STT",
          value: dataHead.totalStt
        },
        {
          name: "Total Koli",
          value: dataHead.totalPiece
        },
        {
          name: "Total Berat Kotor",
          value: convertDecimalWithComma(dataHead.totalGrossWeight, 2)
        },
        {
          name: "Total Berat Dimensi",
          value: convertDecimalWithComma(dataHead.totalVolumeWeight, 2)
        }
      ];
      generateSummaryManifest(`STI Manifest - ${dataHead.stiId}`, fields, logo);
    }
    MainAppController.closeLoading();
  }

  // role account user
  get roleAccount() {
    return (
      (this.dataProfile.account_type === "partner"
        ? this.dataProfile.account_type_detail.type
        : this.dataProfile.account_type) || "internal"
    );
  }
}
