import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  DetailSttPiecesSTI,
  STISTTDueEntities,
  STIEntities,
  STIListBookedEntities,
  StiManifestData,
  STIReverseScanDetail,
  STIReverseScanGenerate,
  STIReverseScanTemporary,
  STIScanFailed,
  STISTTDueSummary
} from "@/domain/entities/STI";
import { STIPresenter } from "@/app/ui/presenters/STIPresenter";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  GenerateStiRequest,
  GenerateSTIReverseScanRequest,
  StiListRequest,
  STIReverseScanManifestRequest,
  STIReverseScanTemporaryListRequest,
  STISTTDueListRequest,
  STISTTDueSummaryListRequest
} from "@/data/payload/api/StiApiRequest";
import { MainAppController } from "./MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { playNotification } from "@/app/infrastructures/misc/UtilsAudio";
import { ErrorMessageEntities, OptionsClass } from "@/domain/entities/MainApp";
import { FAILED_ERROR } from "@/app/infrastructures/misc/Constants";
import { storeDatatoLocalStorage } from "@/app/infrastructures/misc/LocalStorage";

export interface STIState {
  isLoading: boolean;
  isLoadingTemporaryList: boolean;
  STIReverseScanTemporaryList: STIReverseScanTemporary[];
  STIReverseScanGenerateData: STIReverseScanGenerate;
  STIReverseScanDetailData: STIReverseScanDetail;
  STISTTDueSummaryData: STISTTDueSummary;
  bookedListData: STIListBookedEntities;
  scanFailedData: STIScanFailed[];
  sttDueListData: STISTTDueEntities;
  bookedListOptions: OptionsClass[];
}

@Module({ namespaced: true, dynamic: true, store, name: "sti" })
class STIStore extends VuexModule implements STIState {
  isLoading = false;
  isError = false;
  errorCause = "";
  isLoadingSTTSummary = false;
  public periodeStart: any = null;
  public periodeEnd: any = null;
  public STIList = new STIEntities(new Pagination(0, 0), []);
  public isOpenSucess = false;
  public isLoadingPrint = false;
  public isErrorPrint = false;
  public errorPrintCause = "";
  public stiIdToGeneratePdf = 0;
  public stiManifestData = new StiManifestData();
  public sttFailed: Array<string> = [];
  public search = "";
  public STIReverseScanTemporaryList: STIReverseScanTemporary[] = [];
  public STIReverseScanGenerateData: STIReverseScanGenerate = new STIReverseScanGenerate();
  public isLoadingTemporaryList = false;
  public STIReverseScanDetailData: STIReverseScanDetail = new STIReverseScanDetail();
  public STISTTDueSummaryData: STISTTDueSummary = new STISTTDueSummary();
  public bookedListData: STIListBookedEntities = new STIListBookedEntities();
  public scanFailedData: STIScanFailed[] = [];
  public sttDueListData = new STISTTDueEntities();
  public bookedListOptions: OptionsClass[] = [];

  @Action
  public getDetailSttNumber(params: { sttNumber: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(STIPresenter);
    return presenter
      .getDetailSttPieceNumberSti(params.sttNumber)
      .then((res: DetailSttPiecesSTI) => {
        return res;
      })
      .catch((err: any) => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(
            err,
            `Tambah ${
              params.sttNumber.includes("PUM") ? "ID Manifest" : "No. STT"
            } Gagal!`,
            () => this.getDetailSttNumber(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getDetailSttNumberLilo(params: { sttNumber: string }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(STIPresenter);
    return presenter
      .getDetailSttPieceNumberStiLilo(params.sttNumber)
      .then((res: DetailSttPiecesSTI) => {
        return res;
      })
      .catch((err: any) => {
        playNotification("error");
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, `Tambah No. STT Gagal!`, () =>
            this.getDetailSttNumberLilo(params)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getListSTI(params: { payload: StiListRequest; version: string }) {
    this.setIsLoading(true);
    const presenter = container.resolve(STIPresenter);
    return presenter
      .getListSTI(params.payload, params.version)
      .then((res: STIEntities) => {
        this.setSTIList(res);
        this.setErrorCause("");
      })
      .catch((err: any) => {
        this.setErrorCause(err.response ? "server" : "internet");
        this.setSTIList(new STIEntities(new Pagination(0, 0), []));
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  public fetchSTIList(version = "v1") {
    this.getListSTI({
      payload: new StiListRequest({
        page: this.STIList.pagination.page,
        limit: this.STIList.pagination.limit,
        startDate: this.periodeStart?.toISOString().slice(0, 10),
        endDate: this.periodeEnd.toISOString().slice(0, 10),
        search: this.search
      }),
      version
    });
  }

  @Mutation
  initPagination() {
    this.STIList.pagination.page = 1;
  }

  @Action
  public validateSttPiece(sttPiece: string) {
    const presenter = container.resolve(STIPresenter);
    return presenter
      .validationScanSTTPiece(sttPiece)
      .then(() => {
        return {
          allowToPickup: true,
          errorMessage: ""
        };
      })
      .catch((err: any) => {
        return {
          allowToPickup: false,
          errorMessage:
            (err.response && err.response.data.message.id) ||
            "No. STT Pieces tidak ditemukan"
        };
      });
  }

  @Action
  public onGenerateSti(params: { sttNumbers: Array<string> }) {
    this.setIsLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(STIPresenter);
    return presenter
      .generateSti(new GenerateStiRequest(params.sttNumbers))
      .then((res: ResponsePayload) => {
        this.setStiIdToGeneratePdf(res.data.sti_id);
        this.setIsError(false);
        this.setErrorCause("");
        return res;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Perubahan Status Gagal!", () =>
            this.onGenerateSti(params)
          )
        );
        this.setOpenSuccess(false);
        return new ResponsePayload();
      })
      .finally(() => {
        this.setIsLoading(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public getStiManifestDetail(params: {
    id: number;
    version: string;
    print?: boolean;
  }) {
    this.setIsLoadingPrint(true);
    const presenter = container.resolve(STIPresenter);
    return presenter
      .getStiManifestData(params.id, params.version)
      .then((res: StiManifestData) => {
        this.setStiManifestData(res);
        this.setErrorPrint(false);
        this.setErrorPrintCause("");
        return true;
      })
      .catch((err: any) => {
        this.setErrorPrint(true);
        this.setErrorPrintCause(err.response ? "server" : "internet");
        this.setStiManifestData(new StiManifestData());
        const message = err.response.data.message;
        if (params.print) {
          MainAppController.showErrorMessage(
            typeof message === "string"
              ? new ErrorMessageEntities({
                  message: message,
                  title: "Server Error",
                  type: FAILED_ERROR
                })
              : parsingErrorResponse(err, "Gagal Print STI")
          );
        }
        return false;
      })
      .finally(() => this.setIsLoadingPrint(false));
  }

  @Action
  public downloadSti(params: { id: number; version: string, noSti?: boolean }) {
    MainAppController.showLoading();
    const presenter = container.resolve(STIPresenter);
    presenter
      .downloadSti(params.id, params.version, params.noSti || false)
      .catch(err => {
        const message = err.response.data.message;
        MainAppController.showErrorMessage(
          typeof message === "string"
            ? new ErrorMessageEntities({
                message: message,
                title: "Server Error",
                type: FAILED_ERROR
              })
            : parsingErrorResponse(err, "Gagal Print STI")
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Action
  public getListSTIReverseScanTemporary(
    params: STIReverseScanTemporaryListRequest
  ): Promise<STIReverseScanTemporary[]> {
    this.setIsLoadingTemporaryList(true);
    const presenter = container.resolve(STIPresenter);

    return new Promise((resolve, reject) => {
      presenter
        .getListSTIReverseScanTemporary(params)
        .then(res => {
          this.setSTIReverseScanTemporaryData(res);
          resolve(res);
        })
        .catch(err => {
          const message = err.response.data.message;
          MainAppController.showErrorMessage(
            typeof message === "string"
              ? new ErrorMessageEntities({
                  message: message,
                  title: "Server Error",
                  type: FAILED_ERROR
                })
              : parsingErrorResponse(err, "Error")
          );
          reject(err);
        })
        .finally(() => {
          this.setIsLoadingTemporaryList(false);
        });
    });
  }

  @Action
  public generateSTIReverseScan(
    payload: GenerateSTIReverseScanRequest
  ): Promise<STIReverseScanGenerate> {
    this.setSTIReverseScanGenerateData(new STIReverseScanGenerate());
    MainAppController.showLoading();
    this.setIsLoading(true);
    const presenter = container.resolve(STIPresenter);

    return new Promise((resolve, reject) => {
      presenter
        .generateSTIReverseScan(payload)
        .then(res => {
          this.setSTIReverseScanGenerateData(res);
          resolve(res);
        })
        .catch(err => {
          const message = err.response.data.message;

          if (typeof message !== "string") {
            let scanFailedData = this.scanFailedData;
            const errorMessage = err.response.data.message.id;

            if (
              this.scanFailedData.find(
                data => data.sttOrBag === payload.bagOrSttNo
              )
            ) {
              scanFailedData = scanFailedData.map(data => {
                if (data.sttOrBag === payload.bagOrSttNo) {
                  return {
                    ...data,
                    errorMessage
                  };
                }

                return data;
              });
            } else {
              scanFailedData.unshift({
                sttOrBag: payload.bagOrSttNo,
                errorMessage
              });
            }

            this.setScanFailedData(scanFailedData);
            storeDatatoLocalStorage("sti-scan-failed", scanFailedData);
          }

          reject(typeof message === "string" ? err.response : err);
        })
        .finally(() => {
          MainAppController.closeLoading();
          this.setIsLoading(false);
        });
    });
  }

  @Action
  public getDetailSTIReverseScan(id: number) {
    this.setIsLoading(true);
    const presenter = container.resolve(STIPresenter);

    return new Promise((resolve, reject) => {
      presenter
        .getDetailSTIReverseScan(id)
        .then(res => {
          this.setErrorPrint(false);
          this.setErrorPrintCause("");
          this.setSTIReverseScanDetailData(res);
          resolve(res);
        })
        .catch(err => {
          this.setErrorPrint(true);
          this.setErrorPrintCause(err.response ? "server" : "internet");
          reject(err);
        })
        .finally(() => {
          this.setIsLoading(false);
        });
    });
  }

  @Action
  public stiProcessReverse(payload = new STIReverseScanManifestRequest()) {
    const presenter = container.resolve(STIPresenter);
    return presenter.stiProcessReverse(payload);
  }

  @Action
  public getSTISTTDueSummary(
    params: STISTTDueSummaryListRequest
  ): Promise<STISTTDueSummary> {
    this.setIsLoadingSTTSummary(true);
    const presenter = container.resolve(STIPresenter);

    return new Promise((resolve, reject) => {
      presenter
        .getSTISTTDueSummary(params)
        .then(res => {
          this.setSTISTTDueSummaryData(res);
          resolve(res);
        })
        .catch(error => {
          const message = error.response.data.message;
          MainAppController.showErrorMessage(
            parsingErrorResponse(
              typeof message === "string" ? error.response : error,
              "Gagal mengambil data summary"
            )
          );
          reject(error);
        })
        .finally(() => {
          this.setIsLoadingSTTSummary(false);
        });
    });
  }

  @Action
  public getStiBookedList(): Promise<STIListBookedEntities> {
    const presenter = container.resolve(STIPresenter);
    this.setIsLoading(true);

    return new Promise((resolve, reject) => {
      presenter
        .getBookedList()
        .then(res => {
          this.setSTIBookedListData(res);
          resolve(res);
        })
        .catch(err => {
          MainAppController.showErrorMessage(
            parsingErrorResponse(
              err,
              "Gagal mengambil data POS/Klien",
              this.getStiBookedList,
              MainAppController.closeErrorMessage
            )
          );
          reject(err);
        })
        .finally(() => {
          this.setIsLoading(false);
        });
    });
  }

  @Action
  public getStiSttDueList(
    params: STISTTDueListRequest
  ): Promise<STISTTDueEntities> {
    this.setSTTDueData(new STISTTDueEntities());
    const presenter = container.resolve(STIPresenter);

    return new Promise((resolve, reject) => {
      this.setIsLoading(true);

      presenter
        .getSTTDueList(params)
        .then(res => {
          this.setErrorCause("");
          this.setSTTDueData(res);
          resolve(res);
        })
        .catch(error => {
          this.setErrorCause(error.response ? "server" : "internet");
          reject(error);
        })
        .finally(() => {
          this.setIsLoading(false);
        });
    });
  }

  @Action
  public downloadSttDue(params: STISTTDueListRequest) {
    MainAppController.showLoading();
    const presenter = container.resolve(STIPresenter);
    presenter
      .downloadSttDue(params)
      .catch(err => {
        const message = err.response.data.message;
        MainAppController.showErrorMessage(
          typeof message === "string"
            ? new ErrorMessageEntities({
                message: message,
                title: "Server Error",
                type: FAILED_ERROR
              })
            : parsingErrorResponse(
                err,
                "Gagal mengunduh data STT belum di STI!"
              )
        );
      })
      .finally(() => MainAppController.closeLoading());
  }

  @Mutation
  setFirstPage() {
    this.STIList.pagination.page = 1;
  }

  @Mutation
  setIsLoadingPrint(val: boolean) {
    this.isLoadingPrint = val;
  }

  @Mutation
  setStiIdToGeneratePdf(val: number) {
    this.stiIdToGeneratePdf = val;
  }

  @Mutation
  setStiManifestData(val: StiManifestData) {
    this.stiManifestData = val;
  }

  @Mutation
  setErrorPrint(val: boolean) {
    this.isErrorPrint = val;
  }

  @Mutation
  setErrorPrintCause(val: string) {
    this.errorPrintCause = val;
  }

  @Mutation
  public setSTIList(value: STIEntities) {
    this.STIList = value;
  }

  @Mutation
  public setOpenSuccess(val: boolean) {
    this.isOpenSucess = val;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setIsLoadingSTTSummary(value: boolean) {
    this.isLoadingSTTSummary = value;
  }

  @Mutation
  public setIsError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setPeriodeStart(value: Date) {
    this.periodeStart = value;
  }

  @Mutation
  public setPeriodeEnd(value: Date) {
    this.periodeEnd = value;
  }

  @Mutation
  public setSttFailed(value: Array<string>) {
    this.sttFailed = value;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setFilter(val: any) {
    this.periodeStart = val.periodeStart;
    this.periodeEnd = val.periodeEnd;
    this.search = val.search;
  }

  @Mutation
  public setSTIReverseScanTemporaryData(data: STIReverseScanTemporary[]): void {
    this.STIReverseScanTemporaryList = data;
  }

  @Mutation
  public setSTIReverseScanGenerateData(data: STIReverseScanGenerate): void {
    this.STIReverseScanGenerateData = data;
  }

  @Mutation
  public setIsLoadingTemporaryList(bool: boolean): void {
    this.isLoadingTemporaryList = bool;
  }

  @Mutation
  public setSTIReverseScanDetailData(data: STIReverseScanDetail): void {
    this.STIReverseScanDetailData = data;
  }

  @Mutation
  public setSTISTTDueSummaryData(data: STISTTDueSummary): void {
    this.STISTTDueSummaryData = data;
  }

  @Mutation
  public setSTIBookedListData(data: STIListBookedEntities): void {
    this.bookedListData = data;
  }

  @Mutation
  public setScanFailedData(data: STIScanFailed[]): void {
    this.scanFailedData = data;
  }

  @Mutation
  public setSTTDueData(data: STISTTDueEntities): void {
    this.sttDueListData = data;
  }

  @Mutation
  public setFirstPageSTTDue(): void {
    this.sttDueListData.pagination.page = 1;
  }

  @Mutation
  public setBookedListOptions(data: OptionsClass[]) {
    this.bookedListOptions = data;
  }
}

export const STIController = getModule(STIStore);
