import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col px-5 py-3 items-end text-right" }
const _hoisted_2 = {
  key: 1,
  class: "text-red-lp-300 font-montserrat font-bold text-2xl"
}
const _hoisted_3 = { class: "text-gray-lp-300 font-montserrat font-normal text-xs whitespace-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.totalData === 0 ? null : _ctx.goToDetailDashboard())),
    customClass: 
      ` rounded-md border mb-4 border-gray-lp-400 ${
        !_ctx.isLoadingDashboard || _ctx.detailData.total
          ? 'cursor-pointer'
          : 'cursor-not-allowed'
      }`
    ,
    isShadow: false,
    isDisabled: !_ctx.totalData
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        (_ctx.isLoadingDashboard)
          ? (_openBlock(), _createBlock(_component_Spinner, {
              key: 0,
              class: "loader h-8",
              color: "#B82025"
            }))
          : (_openBlock(), _createBlock("p", _hoisted_2, _toDisplayString(_ctx.formatPriceWithoutCurrency(_ctx.totalData) || 0) + " STT ", 1)),
        _createVNode("p", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _: 1
  }, 8, ["customClass", "isDisabled"]))
}