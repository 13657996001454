
import { Options, prop, Vue } from "vue-class-component";
import { STIController } from "@/app/ui/controllers/STIController";
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { DashboardData } from "@/domain/entities/Dashboard";
import { QueryParamsEntities2 } from "@/domain/entities/MainApp";

class Props {
  endKey = prop<string>({
    default: "",
    type: String
  });
  title = prop<string>({
    default: "",
    type: String
  });
  status = prop<string>({
    default: "",
    type: String
  });
  lastStatus = prop<string>({
    default: "",
    type: String
  });
}

@Options({
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess && !this.isPartial) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      STIController.setOpenSuccess(false);
      next();
      this.onClosePartial();
    }
  }
})
export default class Sti extends Vue.with(Props) {
  mounted() {
    this.getDashboard();
    DashboardController.setResetDashboarDetailList();
  }

  cardData = new DashboardData({
    title: this.title,
    detailTitle: "On Process",
    status: "",
    endpoint: "current-stt",
    type: "outgoing",
    fromOutside: `update-${this.status}`
  });

  async getDashboard() {
    const data = await DashboardController.getCutOffConsoleOutgoingStt();
    if (data) {
      DashboardController.fetchDashboardDetailList({
        status: this.isCycleOne ? `${this.lastStatus}1` : `${this.lastStatus}2`,
        type: this.cardData.type,
        tab: String(this.cardData.endpoint),
        posCode: this.cardData.posCode,
        isOutside: true
      });
    }
  }
  get isLoadingDashboard() {
    return DashboardController.isLoading;
  }
  get totalData() {
    return DashboardController.dashboardDetailList.pagination.allTotalData;
  }
  get detailData() {
    return DashboardController.dashboardDetailData;
  }

  get isCycleOne() {
    const cycleOneStart = new Date(
      this.cutOffConsoleOutgoingSttData[0]?.start
    ).getTime();
    const cycleOneEnd = new Date(
      this.cutOffConsoleOutgoingSttData[0]?.end
    ).getTime();

    const currentTime = Date.now();

    return currentTime > cycleOneStart && currentTime <= cycleOneEnd;
  }

  get cutOffConsoleOutgoingSttData() {
    return DashboardController.cutOffConsoleOutgoingSttData.filter(
      key => key.endKey === this.endKey
    );
  }

  formatPriceWithoutCurrency(value: number) {
    return formatPriceWithoutCurrency(value);
  }

  newPath = "";
  get query() {
    return {
      from: this.cardData.endpoint,
      title: this.cardData.detailTitle + " " + this.cardData.title,
      type: this.cardData.type,
      status: this.isCycleOne ? `${this.lastStatus}1` : `${this.lastStatus}2`,
      fromOutside: this.cardData.fromOutside
    };
  }
  goToDetailDashboard() {
    this.newPath = `/dashboard/detail?${
      new QueryParamsEntities2(this.query).queryString
    }`;
    window.open(this.newPath, "_blank");
  }
}
