
import { Options, Vue, prop } from "vue-class-component";

class Props {
  isStiDest = prop<boolean>({
    type: Boolean,
    default: false
  })
}

@Options({})
export default class TakenByRecipientIcon extends Vue.with(Props) {}
