import { getHubData } from "@/app/infrastructures/misc/Cookies";
import {
  STIReverseScanGenerateRequestInterface,
  StiRequestInterface,
} from "../contracts/StiApiRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
/* eslint-disable @typescript-eslint/camelcase */
export class GenerateStiRequest implements StiRequestInterface {
  private sttNumbers: Array<string>;

  constructor(stiPiece: Array<string>) {
    this.sttNumbers = stiPiece;
  }

  public toJSON(): string {
    return JSON.stringify({
      stt_no: this.sttNumbers,
      hub_id: getHubData()?.hubId,
      hub_name: getHubData()?.hubName,
      hub_origin_city: getHubData()?.originCity,
      hub_district_code: getHubData()?.hubDistrictCode,
    });
  }
}

export class StiListRequest {
  page = 1;
  limit = 10;
  startDate = "";
  endDate = "";
  search = "";
  constructor(fields?: Partial<StiListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class GenerateSTIReverseScanRequest
  implements STIReverseScanGenerateRequestInterface {
  bagOrSttNo = "";

  constructor(fields?: Partial<GenerateSTIReverseScanRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const payload = {
      bag_or_stt_no: this.bagOrSttNo,
      hub_id: getHubData().hubId ? Number(getHubData().hubId) : 0,
      hub_name: getHubData().hubName || "",
      hub_district_code: getHubData().hubDistrictCode || "",
      hub_origin_city: getHubData().originCity || ""
    };

    return JSON.stringify(payload);
  }
}

export class STIReverseScanTemporaryListRequest {
  hubId = "0";
  sttOrBagNo = "";
  isStiDest = false;
  statusReturn = "";
  bookedId = "";
  bookedType = "";

  constructor(fields?: Partial<STIReverseScanTemporaryListRequest>) {
    Object.assign(this, fields);
    this.hubId = getHubData().hubId || "0";
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class STIReverseScanManifestRequest {
  hubId = 0;
  bookedId = 0;
  bookedType = "";

  constructor(fields?: Partial<STIReverseScanManifestRequest>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const payload = {
      hub_id: getHubData().hubId || 0,
      booked_id: Number(this.bookedId) as any,
      booked_type: this.bookedType as any
    };

    if (!this.bookedId) {
      delete payload.booked_id;
      delete payload.booked_type;
    }

    return JSON.stringify(payload);
  }
}

export class STISTTDueSummaryListRequest {
  bookedType = "";
  bookedId = 0;
  hubId = 0;

  constructor(fields?: Partial<STISTTDueSummaryListRequest>) {
    Object.assign(this, fields);
    this.hubId = getHubData().hubId ? Number(getHubData().hubId) : 0;
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class STISTTDueListRequest {
  page = 1;
  limit = 20;
  isTotalData = true;
  bookedType = "";
  bookedId = "" as any;
  deadline = "";
  search = "";

  constructor(fields?: Partial<STISTTDueListRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
