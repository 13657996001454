
import { Options, Vue } from "vue-class-component";
import STIUpdateReverseScan from "../../reverse-scan/sti-update-reverse-scan.vue";
import STIUpdateV1 from "./index.vue";
import { FlagsPermissionOutgoingShipmentSTI } from "@/feature-flags/flags-out-going-shipment-sti";

@Options({
  components: {
    STIUpdateV1,
    STIUpdateReverseScan,
  },
})
export default class STIUpdate extends Vue {
  get isReverseScanSTI(): boolean {
    return FlagsPermissionOutgoingShipmentSTI.flag_reverse_scan_sti_enable.isEnabled();
  }

  get renderedPage(): string {
    return this.isReverseScanSTI ? 'STIUpdateReverseScan' : 'STIUpdateV1';
  }
}
